import BaseBean from "@/utils/BaseBean";
import {inject} from "vue";
export interface ICardEngineDataObj {
    utilInst:CardEngineUtil
    engineUtil:any
    refMap:Map<string,any>
    id:string//当前正在操作的卡片id，比如删除的时候就会用到
    mainMaxHeight:number//默认内容的最大高度，实际上在mounted中会动态计算出来
    loading:boolean//打开的时候正在加载效果，没有用指令方式是想局部显示正在加载效果
    buttons:Array<any>//头部按钮
    modelComp:any//本次引擎将包含的组件(外界传入的组件)
    modelCompRef:any//cardEngine包含的组件ref
    formRef:any//表单ref
    form:any//表单
    otherParams:any
}
export default class CardEngineUtil extends BaseBean{
    dialogInst = inject('dialogInst') as any;
    public dataObj:ICardEngineDataObj;
    public props:any
    public context:any
    constructor(proxy:any,dataObj:ICardEngineDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    //初始化引擎中某些参数
    public initDataObjParams():void{
        //1-不管是否采用引擎作为的页面，先把列表传过来的参数赋值一份给otherParams.compParams。
        //需要注意：如果要采用引擎作为页面，那么列表传入参数到引擎的时候，需要指定isEnginePage来覆盖这里的值；而且还要指定compParams参数，
        //compParams参数的结构必须满足自定义业务模块传给引擎的参数一致，比如卡片modelPath，hasDetails等等
        if(this.props.engineParams.compParams)Object.assign(this.dataObj.otherParams.compParams,this.props.engineParams.compParams);
        //2-经过上面之后，就可以知道到底是否采用引擎作为页面了。如果采用引擎作为页面，那么引擎包含的compParams参数也确定了，下方处理的是如果不采用引擎作为页面的时候，
        //怎么让引擎获得具体业务模块的compParams。经过这样的处理之后，引擎中的otherParams.compParams就含有所有的参数了，
        //以后不管是否是采用引擎作为页面，都操作otherParams.compParams即可。
        if(!this.dataObj.otherParams.compParams.isEnginePage)Object.assign(this.dataObj.otherParams.compParams,this.dataObj.modelCompRef.compParams);//业务模块的参数
        //3-如果采用引擎作为页面，那么把form表单挂到proxy身上，这样就和业务模块表单挂在业务模块的proxy一样了，可以减少代码
        if(this.props.engineParams.compParams && this.props.engineParams.compParams.form)Object.assign(this.dataObj.form,this.props.engineParams.compParams.form);

        // this.dataObj.mainMaxHeight=(window.innerHeight-103);//103=按钮：40，title部分：40，底部：23
        this.dataObj.mainMaxHeight=(window.innerHeight-123);//不知道为什么，减去103的话，当内容很多的时候，滚动到底部还是有一些显示不出来，于是就多减了一些
        this.dataObj.modelCompRef.engine=this.proxy;//modelCompRef是cardEngine包含的组件ref，这句话相当于把卡片引擎实例赋值给引擎包含的组件身上
        this.dataObj.modelCompRef.dialogInst=this.dialogInst;//为业务模块添加弹出框对象，方便在模块处使用
    }
}